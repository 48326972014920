import styled from 'styled-components'

export const StyledCategoriesWrapper = styled.div`
  /* z-index: 1; */
  position: relative;
  padding: 50px 0;
  margin-top: -40px;
  background-image: url('/images/categories-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  max-width: auto;
  /* height: 170px; */
  height: 175px;
  margin-bottom: 20px;

  .searchTitle {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    text-align: center;
    margin-top: 50px;
  }
  .promotionTitle {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    text-align: center;
    margin-top: 10px;
  }
  .nav{
      background-color: transparent;
      border: none;
      li {
        color: #ffffff !important;
        border: none;
        border-radius: 15px;
        background-color: #F99B00 !important;
        margin: 0 5px !important;
        padding: 25px !important;
        min-width: 100px;
        max-width: 25%;
        height: 81px;
        white-space: normal;
        word-break: break-word;
        line-height: 1.2;
        text-align: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-blend-mode: overlay;
        overflow: hidden;
        text-overflow: ellipsis;
        span.ant-menu-title-content {
            margin: 0 auto !important;
            vertical-align: middle;
        }
        // &::before {
        //     opacity: 0.5;
        //     background-color: #F99B00;
        //     height: 100%;
        //     position: absolute;
        // }
        &:hover, &.selected{
          border: none !important;
          background-color: #92341B !important;
          color: #fff !important;
        }
      }
  }
  
  .hotline {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
  }

  .csName, .csImages{
    display: inline !important;
  }

  /* For mobile phones: */
  @media only screen and (max-width: 1024px) {  
    .nav {
      li {
        margin: 0 2px !important;
        padding: 5px 5px !important;
      }
    }
  }

  @media only screen and (max-width: 768px) {       
    padding-left: 0;
    padding-right: 0;
    // max-width: 408px;
    height: 148px;
    .searchTitle { display: none; }
    .promotionTitle { margin-top: 30px;}
    .nav {
      li {
        margin: 0 5px !important;
        padding: 10px !important;
        font-size: smaller;
      }
    }
  }
`
