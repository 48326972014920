import { Row, Col, Image, Skeleton } from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledLoaderWrapper = styled.div`
  width: 100%;
  .featured {
    background-color: var(--white);
    border-radius: 10px;
    margin-right: 15px;
    min-height: 409px;
    justify-content: center;
    padding: 24px;
  }
  .card {
    background-color: var(--white);
    min-height: 184px;
    background-color: var(--white);
    justify-content: start;
    align-items: center;
    padding: 12px;
    margin-right: 15px;
    margin-bottom: 15px;
    border-radius: 10px;
  }
`
export default function Index ({ isFeatured = false, count = 6 }) {
  return (
    <StyledLoaderWrapper>
      {isFeatured && (
        <Row justify='space-between'>
          {[...Array(3)].map((_, index) => (
            <Col key={index} className='featured text-center' xs={24} sm={24} md={7} lg={7}>
              <Image src='/images/placeholder.png' alt='Peri Peri' preview={false} />
              <Skeleton active />
            </Col>
          ))}
        </Row>
      )}
      {!isFeatured && (
        <Row justify='space-between'>
          {[...Array(count)].map((_, index) => (
            <Col key={index} xs={24} sm={24} md={12} lg={8}>
              <Row className='card'>
                <Col span={8}>
                  <Image src='/images/placeholder.png' alt='Peri Peri' preview={false} />
                </Col>
                <Col span={15} offset={1}>
                  <Skeleton active />
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      )}
    </StyledLoaderWrapper>
  )
}

Index.propTypes = {
  isFeatured: PropTypes.bool,
  count: PropTypes.number
}
