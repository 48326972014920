/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Link from 'next/link'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Styled } from './styles'

// Default Menu Slider
export const MenuCarousel = ({ state, categories, categorySlug, filterByCategory, ...props }) => {
  const router = useRouter()
  const [currentIndex, setCurrentIndex] = useState(null)
  const [activeSlug, setActiveSlug] = useState(categorySlug)

  useEffect(() => {
    if (localStorage.getItem('menuIndex') !== null) {
      setCurrentIndex(localStorage.getItem('menuIndex'))
    }
    if (localStorage.getItem('menuSlug') !== null) {
      setActiveSlug(localStorage.getItem('menuSlug'))
    }
  }, [router])

  return (
    <Styled { ...props } initialSlide={currentIndex} afterChange={(current) => localStorage.setItem('menuIndex', current)}>
      {state.categories.status === 2 && categories.map((x, index) =>
        <Link href={`/catalog/categories/${x.slug}`} onClick={() => filterByCategory(x.slug, index)} key={x.slug}>
          <li onClick={() => localStorage.setItem('menuSlug', x.slug)} className={(String(activeSlug) === String(x.slug)) ? 'selected' : ''} style={{ backgroundImage: `url(${x.image_url || '/images/bg/More.png'})` }}>
            <div className='label'>{x.name}</div>
          </li>
        </Link>
      )}
    </Styled>)
}

MenuCarousel.propTypes = {
  state: PropTypes.any,
  categories: PropTypes.any,
  filterByCategory: PropTypes.func,
  categorySlug: PropTypes.any
}

// End of Default Menu Slider
