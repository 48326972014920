import PropTypes from 'prop-types'
import { Default } from './default/index'
import { MenuCarousel } from './carousel/index'

// Default Menu Slider
export const MenuSlider = ({ state, categories, ...props }) => {
  return (
    <Default state={state} categories={categories} {...props} />
  )
}

MenuSlider.propTypes = {
  state: PropTypes.any,
  categories: PropTypes.any
}
// End of Default Menu Slider

// Default Menu Carousel
export const Carousel = ({ state, categories, filterByCategory, categorySlug, ...props }) => {
  return (
    <MenuCarousel
      state={state}
      categories={categories}
      filterByCategory={filterByCategory}
      categorySlug={categorySlug}
      {...props}
    />
  )
}

Carousel.propTypes = {
  state: PropTypes.any,
  categories: PropTypes.any,
  filterByCategory: PropTypes.func,
  categorySlug: PropTypes.any
}
// End of Menu Carousel
