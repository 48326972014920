import PropTypes from 'prop-types'
import PageHeader from 'components/PageHeader'
import Categories from 'components/Catalog'
import PageFooter from 'components/PageFooter'
import { Layout } from 'antd'
import { StyledLayout } from 'components/GlobalStyles'

const { Content } = Layout

export default function Index ({ children }) {
  return (
    <StyledLayout>
      <PageHeader />
      <div>
        <Categories />
      </div>
      <Content>
        {children({})}
      </Content>
      <PageFooter />
    </StyledLayout>
  )
}

Index.propTypes = {
  children: PropTypes.any
}
