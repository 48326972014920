import PropTypes from 'prop-types'
import { Styled, StyledMore } from './styles'

// Default Menu Slider
export const Default = ({ state, categories, ...props }) => {
  return (
    <Styled {...props}>
      {state.categories.status === 2 && categories.slice(0, 4).map(x =>
        <Styled.Item key={x.id} style={{ backgroundImage: `url(${x.image_url || '/images/shakeys-logo.png'})` }}>
          {x.name}
        </Styled.Item>
      )}
      <StyledMore.Item key='more'>
        {state.categories.status === 1 ? 'LOADING...' : 'MORE...'}</StyledMore.Item>
    </Styled>)
}

Default.propTypes = {
  state: PropTypes.any,
  categories: PropTypes.any
}

// End of Default Menu Slider
