import { Button, Col, Row } from 'antd'
import { useRouter } from 'next/router'
import { STORE_LOCATOR_AVAILABLE } from 'utils/constants'
import styles from './index.module.scss'

export default function Index () {
  const router = useRouter()

  return (
    <Row className={styles.main} gutter={32}>
      <Col
        xs={{ span: 0 }}
        sm={{ span: 0 }}
        md={{ span: 24 }}
        lg={{ span: 24 }}
      >
        <Row>
          <Col
            xs={{ span: 0 }}
            sm={{ span: 0 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
          />
          <Col
            xs={{ span: 0 }}
            sm={{ span: 0 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
          />
          <Col
            xs={{ span: 0 }}
            sm={{ span: 0 }}
            md={{ span: 10, offset: 2 }}
            lg={{ span: 10, offset: 2 }}
          >
            <Row style={{ marginLeft: 15 }} align='middle'>
              <Col
                xs={{ span: 0 }}
                sm={{ span: 0 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <h3
                  style={{
                    margin: '20px 0 20px 0 !important',
                    color: 'var(--cognac)'
                  }}
                >
                  Store Locators and Hours
                </h3>
                <p>
                  We have opened more stores and extended our store hours to
                  give you more services!
                </p>
                <Button
                  disabled={!STORE_LOCATOR_AVAILABLE}
                  onClick={() => router.push('/store-locator')}
                  type='primary'
                  className='cognac-btn'
                >
                  Find Store
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 0 }}
        lg={{ span: 0 }}
      >
        <Row className={styles.content}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 14 }}
            lg={{ span: 14 }}
          >
            <h3 style={{ lineHeight: '30px', color: 'var(--cognac)' }}>
              Store Locators and Hours
            </h3>
            <br />
            <p>
              We have opened more stores and extended our store hours to give
              you more services!
            </p>
            <br />
            <Button
              disabled={!STORE_LOCATOR_AVAILABLE}
              onClick={() => router.push('/store-locator')}
              type='primary'
              className='cognac-btn'
            >
              Find Store
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
