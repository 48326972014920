import { useEffect, useState } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { useRouter } from 'next/router'

export default function Container ({ children }) {
  const router = useRouter()
  const { query } = router
  const { id, itemCode } = router.query
  const isHome = router.pathname === '/'
  const searching = router.pathname.split('/').includes('search')
  const promotions = router.pathname.split('/').includes('promos')
  const cs = {
    swipeToSlide: true,
    slidesToShow: 6,
    speed: 500,
    className: 'slider variable-width',
    dots: false,
    infinite: true,
    variableWidth: true,
    arrows: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          infinite: true,
          dots: false
        }
      }
    ]
  }

  const state = useStoreState((s) => ({
    me: s.me,
    logoutState: s.logoutState,
    categories: s.categories,
    cartItems: s.cartItems,
    promos: s.promos,

    // supercard v2
    scCardsV2: s.scCardsV2
  }))

  const actions = useStoreActions((a) => ({
    getSearchProducts: a.getSearchProducts,
    getCategories: a.getCategories,
    setCategories: a.setCategories,
    getProducts: a.getProducts,
    setProducts: a.setProducts,
    getFeaturedProducts: a.getFeaturedProducts,
    setFeaturedProducts: a.setFeaturedProducts,
    scGetCards: a.scGetCards
  }))

  const [me, setMe] = useState(null)
  const [promo, setPromo] = useState(null)
  const [queries, setQueries] = useState(query)
  const [categories, setCategories] = useState([])
  const [categorySlug, setCategorySlug] = useState(null)
  const [settings, setSettings] = useState(cs)

  useEffect(() => {
    if (typeof sessionStorage.getItem('hasSuperCard') !== 'undefined') {
      actions.getCategories()
    }

    if (typeof query.search !== 'undefined') {
      actions.getSearchProducts({ search: query.search })
    }

    return () => {
      actions.setProducts({ status: 0, payload: null })
      actions.setCategories({ status: 0, payload: null })
      actions.setFeaturedProducts({ status: 0, payload: null })
    }
  }, [])

  useEffect(() => {
    setCategorySlug(query.category_slug)
    const { status, payload } = state.categories
    if (
      query.category_slug &&
      status === 2 &&
      typeof payload.results !== 'undefined'
    ) {
      const category = payload.results.filter(
        (category) => category.id === query.category_slug
      )
      if (category.length > 0) {
        actions.getProducts({ category_slug: category[0].slug })
      }
    }
  }, [query])

  useEffect(() => {
    const { status, payload } = state.me
    if (status === 2 && payload.details) {
      setMe(payload.details)
    } else {
      setMe(null)
    }
  }, [state.me])

  useEffect(() => {
    if (
      promotions &&
      state.promos.status === 2 &&
      state.promos.payload.count > 0
    ) {
      setPromo(
        state.promos.payload.headers.filter(
          (x) => x.details.itemCode === itemCode || Number(x.id) === Number(id)
        )[0]
      )
    }
  }, [state.promos])

  useEffect(() => {
    const { status } = state.logoutState
    if (status === 2) {
      let cats = state.categories.payload.results.filter(
        (x) => x.in_menu === true
      )
      cats = cats.filter((x) => x.is_supercard_exclusive === false)
      cs.slidesToShow = Math.min(6, cats.length)
      // cs.arrows = Boolean(isHome && window?.innerWidth < 768)
      setSettings(cs)
      if (!isHome) {
        setCategories(cats)
      } else {
        // cs.slidesToShow = 5
        const c = cats.slice(0, 4)
        c.push({
          id: 'more',
          slug: 'more',
          name: 'MORE'
        })
        setCategories(c)
      }
    }
  }, [state.logoutState])

  useEffect(() => {
    const { status, payload } = state.categories
    if (
      status === 2 &&
      typeof payload.results !== 'undefined' &&
      (state.scCardsV2.status === 0 ||
        state.scCardsV2.status === 2 ||
        state.scCardsV2.status === 3)
    ) {
      let cats = payload.results.filter((x) => x.in_menu === true)
      if (
        state.scCardsV2.payload?.Cards?.length === 0 ||
        state.scCardsV2.payload?.Cards === null
      ) {
        cats = cats.filter((x) => x.is_supercard_exclusive === false)
      }
      cs.slidesToShow = Math.min(6, cats.length)
      // cs.arrows = !isHome
      setSettings(cs)
      if (!isHome) {
        setCategories(cats)
      } else {
        cs.slidesToShow = cats.length > 0 ? 5 : 1
        const c = cats.slice(0, 4)
        c.push({
          id: 'more',
          slug: 'more',
          name: 'MORE'
        })
        setCategories(c)
      }
    }
  }, [state.categories, state.scCardsV2])

  const filterByCategory = (categorySlug) => {
    // query.category_slug = categorySlug
    setQueries(query)
    setCategorySlug(categorySlug)
    router.push('/catalog/categories/' + categorySlug)
  }

  const onSearch = (search) => {
    actions.getSearchProducts({ search: search })
    router.push('/catalog/search/' + search)
  }

  return children({
    me,
    state,
    categories,
    queries,
    isHome,
    filterByCategory,
    searching,
    onSearch,
    categorySlug,
    settings,
    promotions,
    promo
  })
}
