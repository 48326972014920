import { Col, Row } from 'antd'
import MediumContainer from 'components/MediumContainer'
import { Carousel } from 'components/MenuSlider'
import { MrPeriImage, TopBanner } from 'components/PromoBanner'
import ViewContainer from './container'
import { StyledCategoriesWrapper } from './styles'

export default function Index () {
  return (
    <ViewContainer>
      {({
        state,
        me,
        categories,
        categorySlug,
        queries,
        isHome,
        filterByCategory,
        searching,
        onSearch,
        promotions, // promo checker
        promo, // promo details
        settings
      }) => (
        <>
          {isHome && <TopBanner />}

          <StyledCategoriesWrapper>
            <MediumContainer>
              {!searching && !promotions && (
                <>
                  {isHome && (
                    <Row>
                      {/** Floating Mr Peri Image */}
                      <MrPeriImage />
                      {/** Floating Mr Peri Image */}
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 20, offset: 4 }}
                      >
                        <Carousel
                          {...settings}
                          categorySlug={categorySlug}
                          onClick={filterByCategory}
                          state={state}
                          categories={categories}
                        />
                      </Col>
                    </Row>
                  )}
                  {!isHome && (
                    <Carousel
                      {...settings}
                      categorySlug={categorySlug}
                      onClick={filterByCategory}
                      state={state}
                      categories={categories}
                    />
                  )}
                </>
              )}
            </MediumContainer>
          </StyledCategoriesWrapper>
        </>
      )}
    </ViewContainer>
  )
}
