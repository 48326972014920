import { Menu } from 'antd'
import styled from 'styled-components'
export const Styled = styled(Menu)`
`

export const StyledMore = styled(Menu)`
  @media only screen and (max-width: 768px) {
    display: none !important;
  }
`
