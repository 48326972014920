import PropTypes from 'prop-types'
import accounting from 'accounting'
import { Row, Col, Image, Button } from 'antd'
import Link from 'next/link'

export default function Index ({ product }) {
  return (
    product && (
      <Link href={'/catalog/' + product.id}>
        <Row align='middle' className='product-box height-100 shadow'>
          <Col span={24} className='center-vh main-product-img'>
            <Image src={product.image_url} alt='' preview={false} />
          </Col>
          {product.is_new && (
            <Col span={7} className='ribbon'>
              <Image src='/images/New-Ribbon2.png' alt='' preview={false} />
            </Col>
          )}
          <Col span={20} offset={2} className='text-center'>
            <Row>
              <Col span={24}>
                <h3
                  className='main-product-title'
                  style={{
                    color: 'var(--cognac)',
                    lineHeight: '30px !important'
                  }}
                >
                  {product.name}
                  {product.is_super_card_exclusive && (
                    <img
                      style={{ marginLeft: 10 }}
                      src='/images/supercard_v2/sc-icon-v2.png'
                      alt=''
                    />
                  )}
                </h3>
              </Col>
              <Col span={24} className='mt-2 main-product-description'>
                <span>
                  <div
                    dangerouslySetInnerHTML={{ __html: product.description }}
                  />
                </span>
              </Col>
            </Row>
            <Row className='mp-footer-container'>
              <Col
                className='main-product-starts-at'
                xs={{ span: 14, offset: 0 }}
                sm={{ span: 14, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 13, offset: 0 }}
              >
                <Row>
                  <Col span={24}>
                    <Button type='text'>
                      {accounting.formatMoney(product.price, '₱', 2)}
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col
                className='mp-btn'
                xs={{ span: 6, offset: 2 }}
                sm={{ span: 6, offset: 3 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 6, offset: 2 }}
              >
                <Link href={'/catalog/' + product.id}>
                  <a>
                    <Button type='primary'>ORDER</Button>
                  </a>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Link>
    )
  )
}

Index.propTypes = {
  product: PropTypes.any
}
